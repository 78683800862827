import React from 'react'
import { ButtonLink } from 'src/components/ButtonLink'
import { ImageWebpWithSizes } from 'src/components/ImageWebp'
import SectionComponent from 'src/components/SectionComponent'
import { InterP } from 'src/components/Text/BodyText'
import { H2Citrina, H3Citrina } from 'src/components/Text/Headings'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_LG, WIDTH_MD, WIDTH_SM } from 'src/styles/breakpoints'
import { sand } from 'src/styles/newColors'
import interLoopLogo from '../../assets/inter-loop.png'
import { TextContent } from '../../types/_index'
import { GridContainer, InfoBox } from './_style'

const CONTENT: TextContent = {
  heading: 'Programa de pontos e benefícios para a sua empresa',
  paragraph: 'Sua empresa também merece as melhores vantagens: o cartão de crédito Inter Empresas acumula pontos e garante flexibilidade para escolher os benefícios que mais combinam com o seu negócio.',
  cta: 'Conheça os benefícios',
}

type BoxItems = {
  id: string;
  title: string;
  body: string;
};

const boxItems: BoxItems[] = [
  {
    id: 'inter_loop_empresas_1',
    title: 'Desconto na fatura da sua empresa',
    body: 'Converta seus pontos em desconto na próxima fatura do cartão de crédito e economize dinheiro.',
  },
  {
    id: 'inter_loop_empresas_2',
    title: 'Cashback direto na conta',
    body: 'O cashback que você já conhece: aproveite seus pontos para ganhar dinheiro de volta na conta corrente de sua empresa',
  },
  {
    id: 'inter_loop_empresas_3',
    title: 'Mais desconto em compras',
    body: 'Converta seus pontos em desconto extra no shopping do Inter e turbine a economia na hora de fazer compras para sua empresa',
  },
]

export const InterLoopEmpresas = () => {
  const width = useWidth()
  const showSMLayout = width <= WIDTH_SM && width < WIDTH_MD
  const showMDLayout = width >= WIDTH_MD && width < WIDTH_LG
  return (
    <SectionComponent
      id='inter_loop_empresas'
      bgColor={sand}
      minHeight={{ sm: '975px', md: '720px', lg: '531px', xl: '664px', xxl: '664px' }}
    >
      {showSMLayout && (
        <InterLoopEmpresas.SMLayout />
      )}
      {showMDLayout && (
        <InterLoopEmpresas.MDLayout />
      )}
      {width >= WIDTH_LG && (
        <InterLoopEmpresas.LGLayout />
      )}
    </SectionComponent>
  )
}

InterLoopEmpresas.SMLayout = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <>
      <div className='col-12 mb-3'>
        <ImageWebpWithSizes pathSrc={interLoopLogo} altDescription='logo interloop' widths={{ sm: 202 }} heights={{ sm: 83 }} />
      </div>
      <div className='col-12 mt-3'>
        <H2Citrina className='mb-3'>{CONTENT.heading}</H2Citrina>
        <InterP className='mb-3'>{CONTENT.paragraph}</InterP>
        {boxItems.map((item: BoxItems) => (
          <InfoBox key={item.id} className='mb-3'>
            <header>
              <H3Citrina fontSize={{ lg: '20px', xl: '20px' }} lineHeight={{ lg: '24px', xl: '24px' }}>{item.title}</H3Citrina>
            </header>
            <div>
              <InterP>{item.body}</InterP>
            </div>
          </InfoBox>
        ))}
        <ButtonLink
          className='mx-auto d-block'
          target='_blank'
          rel='noopener'
          onClick={() => {
            sendDatalayerEvent({
              section: 'dobra_03',
              element_action: 'click button',
              section_name: CONTENT.heading,
              element_name: CONTENT.cta ?? '',
              redirect_url: 'https://inter.co/empresas/programa-de-pontos-para-empresas/',
            })
          } }
          width='100%'
          height='48px'
          href='https://inter.co/empresas/programa-de-pontos-para-empresas/'
        >
          {CONTENT.cta}
        </ButtonLink>
      </div>
    </>
  )
}

InterLoopEmpresas.MDLayout = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()
  const btnWidth = width <= WIDTH_SM ? '100%' : '452px'
  return (
    <>
      <div className='row w-100'>
        <div className='col-md-6 mb-3'>
          <ImageWebpWithSizes
            pathSrc={interLoopLogo}
            altDescription='logo interloop'
            widths={{ sm: 202, md: 202 }}
            heights={{ sm: 83, md: 83 }}
            className='mb-4'
          />
          <H2Citrina className='mb-3'>{CONTENT.heading}</H2Citrina>
          <InterP>{CONTENT.paragraph}</InterP>
        </div>
        <div className='col-md-6'>
          <ImageWebpWithSizes
            pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra3-mobile-loop/image.webp'
            altDescription=''
            aria-hidden='true'
            widths={{ md: 226 }}
            heights={{ md: 308 }}
            className='mx-auto d-block'
          />
        </div>
      </div>
      <div className='row w-100 mb-3'>
        <div className='col-md-12 d-flex justify-content-around'>
          {boxItems.map((item: BoxItems) => (
            <InfoBox key={item.id}>
              <header>
                <H3Citrina>{item.title}</H3Citrina>
              </header>
              <div>
                <InterP fontSize={{ md: '14px' }} lineHeight={{ md: '16px' }}>{item.body}</InterP>
              </div>
            </InfoBox>
          ))}
        </div>
      </div>
      <div className='row w-100 mt-2'>
        <ButtonLink
          className='mx-auto d-block'
          target='_blank'
          rel='noopener'
          onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_03',
                element_action: 'click button',
                section_name: CONTENT.heading,
                element_name: CONTENT.cta ?? '',
                redirect_url: 'https://inter.co/empresas/programa-de-pontos-para-empresas/',
              })
          } }
          width={btnWidth}
          height='48px'
          href='https://inter.co/empresas/programa-de-pontos-para-empresas/'
        >{CONTENT.cta}
        </ButtonLink>
      </div>
    </>
  )
}

InterLoopEmpresas.LGLayout = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <div className='row w-100'>
      <div className='col-lg-5 mb-3'>
        <ImageWebpWithSizes
          pathSrc={interLoopLogo}
          altDescription='logo interloop'
          widths={{ sm: 202, md: 202, lg: 202, xl: 202 }}
          heights={{ sm: 83, md: 83, lg: 83, xl: 83 }}
          className='mb-4'
        />
        <H2Citrina className='mb-3'>{CONTENT.heading}</H2Citrina>
        <InterP className='mb-3'>{CONTENT.paragraph}</InterP>
        <ButtonLink
          className='mx-auto d-block'
          target='_blank'
          rel='noopener'
          onClick={() => {
            sendDatalayerEvent({
              section: 'dobra_03',
              element_action: 'click button',
              section_name: CONTENT.heading,
              element_name: CONTENT.cta ?? '',
              redirect_url: 'https://inter.co/empresas/programa-de-pontos-para-empresas/',
            })
          } }
          width='100%'
          height='48px'
          href='https://inter.co/empresas/programa-de-pontos-para-empresas/'
        >
          {CONTENT.cta}
        </ButtonLink>
      </div>
      <div className='col-lg-6 offset-lg-1'>
        <GridContainer>
          {boxItems.map((item: BoxItems) => (
            <InfoBox key={item.id}>
              <header>
                <H3Citrina fontSize={{ lg: '20px', xl: '20px' }} lineHeight={{ lg: '24px', xl: '24px' }}>{item.title}</H3Citrina>
              </header>
              <div>
                <InterP fontSize={{ lg: '14px', xl: '14px' }} lineHeight={{ lg: '17px', xl: '17px' }}>{item.body}</InterP>
              </div>
            </InfoBox>
          ))}
          <ImageWebpWithSizes
            pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/moeda-loop/image.webp'
            altDescription=''
            aria-hidden='true'
            heights={{ lg: 175, xl: 175, xxl: 175, xxxl: 175 }}
            widths={{ lg: 175, xl: 175, xxl: 175, xxxl: 175 }}
            className='mx-auto d-block'
          />
        </GridContainer>
      </div>
      <div className='col-lg-12 mt-2' />
    </div>
  )
}
