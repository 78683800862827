
import { breakpoints } from 'src/styles/breakpoints'
import { primary } from 'src/styles/newColors'
import styled from 'styled-components'

export const SectionWrapper = styled.div`
  background-color: ${primary[500]};
  border-top-right-radius: 70px;
  border-top-left-radius: 115px;
  border-bottom-right-radius: 115px;
  border-bottom-left-radius: 70px;
  padding: 0 10px;
  max-width: 95%;
  margin: 0 auto;
  button {
    background-color: #fff;
    color: ${primary[500]};

    &:hover {
      background-color: #fff!important;
      color: ${primary[500]};
    }
  }

  @media (min-width: ${breakpoints.md}) {
    border-radius: 0;
    padding: 0;
    max-width: 100%;
  }

  a {
    background-color: #fff;
    width: 100%;
    max-width: 456px;
    border-radius: 8px;
    color: ${primary[500]};
    height: 48px;
    text-align: center;
    padding: 16px 24px 16px 24px;
    font-family: "Sora", Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    display: inline-block;
  }
`

export const ImageWrapper = styled.div`
  position: absolute;
  width: 50%;
  height: 100%;
  border-bottom-right-radius: 45px;
  overflow: hidden;
  max-width: 380px;
  @media (min-width: ${breakpoints.lg}) {
    max-width: 466px;
  }

  @media (min-width: ${breakpoints.xl}) {
    max-width: 688px;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
